import { ReactNode } from 'react';
import Popup, { PopupProps } from '~/components/interactive/Popup';

export function TooltipContent({ children }: { children: ReactNode }) {
  return (
    <div className="rounded-md max-w-64 bg-gray-800 bg-opacity-80 text-white text-xs tracking-widest font-semibold antialiased uppercase text-center px-2 py-0.5">
      {children}
    </div>
  );
}

export default function Tooltip({
  text,
  children,
  strategy = 'best',
}: {
  text: string;
  children: ReactNode;
  strategy?: PopupProps['strategy'];
}) {
  return (
    <Popup activator={children} hover={0} className="inline-block cursor-default" strategy={strategy}>
      <TooltipContent>{text}</TooltipContent>
    </Popup>
  );
}
